<template>
  <div>
    <SubgroupDetail v-if="layout === 'subgroup'" />
    <Disease v-if="layout === 'disease'" />
    <div v-else-if="layout === 404">
      <Choch class="inPage" />
    </div>
    <div v-else-if="layout === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import Disease from "@/views/Disease.vue";
import SubgroupDetail from "@/views/SubgroupDetail.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    layout: null,
  }),
  name: "SubgroupLayout",
  components: { SubgroupDetail, Disease, Choch, Error },
  methods: {
    ...mapActions(["fetchSubgroupDetail", "fetchDisease"]),
    async getLayout() {
      let isSubgroup = await this.fetchSubgroupDetail({
        nos_slug: this.$route.params.slug,
        subgroup_slug: this.$route.params.disease,
        access: this.$route.query.access || null
      });
      let isDisease = await this.fetchDisease({
        disease: this.$route.params.disease,
        slug: this.$route.params.slug,
        access: this.$route.query.access || null
      });
      if (isSubgroup === true) {
        this.layout = "subgroup";
      } else if (isDisease === true) {
        this.layout = "disease";
      } else if (isSubgroup === 404) {
        this.layout = 404;
      } else if (isDisease === 404) {
        this.layout = 404;
      } else if (isSubgroup === false) {
        this.layout = false;
      } else if (isDisease === false) {
        this.layout = false;
      }
    },
  },
  mounted() {
    this.getLayout();
  },
};
</script>

<style>
</style>